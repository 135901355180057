<template>
    <div class="formInput">
        <!--普通输入框-->
        <a-input :placeholder="data.hintText" v-if="data.fieldType=='textinput'" style="width: 100%" @change="_getData"></a-input>

        <!--多行文本-->
        <a-input :placeholder="data.hintText" v-if="data.fieldType=='textarea'" style="width: 100%" @change="_getData"></a-input>

        <!--单选-->
        <a-select :placeholder="data.hintText" v-if="data.fieldType=='radio'" style="width: 100%" @change="_getData">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="tab" v-for="tab in data.defaultValue">{{tab}}</a-select-option>
        </a-select>

        <!--多选-->
        <a-select mode="multiple" style="width: 100%" :placeholder="data.hintText" v-if="data.fieldType=='checkbox'" :maxTagCount="1" @change="_getData">
            <a-select-option :value="tab" v-for="tab in data.defaultValue">{{tab}}</a-select-option>
        </a-select>

        <!--日期选择-->
        <a-date-picker :placeholder="data.hintText" v-if="data.fieldType=='date'" @change="_getData" />

        <!--日期范围-->
        <a-range-picker :placeholder="data.hintText" v-if="data.fieldType=='daterange'" @change="_getData" />
    </div>
</template>

<script>
    export default {
        name: "formInput",
        data(){
            return {

            }
        },
        props: {
            data: {
                default: {}
            }
        },
        methods: {
            _getData(e){
                let data = {};
                if(this.data.fieldType == "textinput" || this.data.fieldType == "textarea"){

                    data[this.data.fieldValue] = e.target.value;

                }else if(this.data.fieldType == "radio"){

                    data[this.data.fieldValue] = e;

                }else if(this.data.fieldType == "checkbox"){

                    data[this.data.fieldValue] = e;

                }else if(this.data.fieldType == "date"){

                    data[this.data.fieldValue] = this.__moment__(e).format('YYYY-MM-DD');

                }else if(this.data.fieldType == "daterange"){

                    data[this.data.fieldValue] = [this.__moment__(e[0]).format('YYYY-MM-DD'),this.__moment__(e[1]).format('YYYY-MM-DD')]

                }
            }
        }
    }
</script>

<style scoped lang="less">
    .formInput{

    }
</style>