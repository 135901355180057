<template>
    <div class="index">
        <div class="header flex">
            <div>
                <template v-for="(item,index) in infoData.fieldList">
                    <div class="search-item">
                        <formInput :data="item"></formInput>
                        <!--<a-select :placeholder="item.hintText" v-if="item.fieldType=='radio'" style="width: 100%">-->
                            <!--<a-select-option value="">全部</a-select-option>-->
                            <!--<a-select-option :value="tab" v-for="tab in item.defaultValue">{{tab}}</a-select-option>-->
                        <!--</a-select>-->

                        <!--<a-input :placeholder="item.hintText" v-if="item.fieldType=='textinput'"></a-input>-->
                    </div>
                </template>

                <a-button style="margin-left: 15px;" @click="_reset">重置</a-button>
                <a-button type="primary" style="margin-left: 15px;" @click="_search">查询</a-button>
            </div>

            <div>
                <a-button type="primary" style="margin-left: 15px;" @click="_addShow">新建故事</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    import formInput from  'components/tableTemplate/formInput';
    export default {
        name: "index",
        components: {
            formInput
        },
        data(){
            return {
                infoData: {
                    fieldList: [
                        {
                            defaultValue: '',
                            fieldTitle: "姓名",
                            fieldType: "textinput",
                            fieldValue: "searchName",
                            hintText: "请输入",
                        },
                        {
                            defaultValue: ['是','否'],
                            fieldTitle: "是否发布",
                            fieldType: "radio",
                            fieldValue: "isIssue",
                            hintText: "请选择",
                        },
                        {
                            defaultValue: ['苹果','黄瓜','菠萝','香蕉','甘蔗'],
                            fieldTitle: "那些是水果",
                            fieldType: "checkbox",
                            fieldValue: "isFruits",
                            hintText: "请选择",
                        },
                        {
                            defaultValue: '',
                            fieldTitle: "简介",
                            fieldType: "textarea",
                            fieldValue: "duohangwenben",
                            hintText: "请输入",
                        },
                        {
                            defaultValue: '',
                            fieldTitle: "放假时间",
                            fieldType: "date",
                            fieldValue: "fangjiaTime",
                            hintText: "请选择",
                        },
                        {
                            defaultValue: '',
                            fieldTitle: "放假时间",
                            fieldType: "daterange",
                            fieldValue: "fangjiaTime",
                            hintText: ['开始时间','结束时间'],
                        }
                    ]
                }
            }
        },
        methods: {
            _addShow(){

            },
            _search(){

            },
            _reset(){

            },
        }
    }
</script>

<style scoped lang="less">
    .index{
        width: 100%;
        min-height: 100%;
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        .header{
            margin-top: 10px;
            .search-item{
                display: inline-block;
                width: 160px;
                margin-right: 15px;
                input{
                    width: 100%;
                }
            }
        }
    }
</style>